<template>
  <div class="page">
    <div class="page-main">
      <div class="main-content">
        <svg class="icon svg-icon left-content" aria-hidden="true">
          <use xlink:href="#icon-authorized"></use>
        </svg>
        <!-- <div class="left-content">
          <img src="@/assets/images/authorized.png" v-show="!isAuthorized">
          <img src="@/assets/images/no-authorized.png" v-show="isAuthorized">
        </div> -->
        <div class="right-content">
          <div class="tip" v-if="!isAuthorized">{{ $t('authorizedChannels.tip1') }}</div>
          <div class="tip" v-else>
            {{ $t('authorizedChannels.tip2start') }}
            {{ authoriedChannel }}
            {{ $t('authorizedChannels.tip2end') }}
          </div>
          <el-input
            v-show="!isAuthorized"
            v-model="emailAddress"
            :placeholder="$t('authorizedChannels.placeholder')"
            clearable
            @focus="error = false"
          ></el-input>
          <!-- <div class="error" v-show="errorMsg">{{errorMsg}}</div> -->
          <el-button
            class="btn"
            type="primary"
            v-show="!isAuthorized"
            @click="toGrantAuthorization"
          >{{ $t('authorizedChannels.immediately') }}</el-button>
          <el-button
            class="btn"
            type="primary"
            v-show="isAuthorized"
            @click="cancelAuthorization"
          >{{ $t('authorizedChannels.cancel') }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  isAuthorized,
  toGrantAuthorization,
  cancelAuthorized
} from '@/api/authorized'

export default {
  name: 'AuthorizedChannels',
  data() {
    return {
      error: false,
      errorMsg: '',
      emailAddress: '',
      authoriedChannel: '',
      isAuthorized: false,
    }
  },
  mounted(){
    this.checkAuthorization()
  },
  methods:{
    checkAuthorization() {
      isAuthorized()
        .then(({ data }) => {
          if (data.result.rows.length) {
            this.isAuthorized = true
            this.authoriedChannel = data.result.rows[0].name
          }
        })
    },
    toGrantAuthorization(){
      if (this.emailAddress == '') {
        this.errorMsg = 'Email cannot be empty'
      } else {
        this.errorMsg = ''
        toGrantAuthorization({ email: this.emailAddress })
          .then(res => {
            if (res.data.code === 0) {
              this.emailAddress = ''
              this.checkAuthorization()
            }
          })
          .catch(res => {
            if (res.data.code === 10000) {
              this.errorMsg = res.data.message
            }
          })
      }
    },
    cancelAuthorization(){
      cancelAuthorized().then(res => { 
        if(res.data.code === 0){
          this.isAuthorized =false
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.main-content{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 630px;
  height: 190px;
  margin: 65px auto 0;
  padding: 33px 35px 44px 76px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  .left-content{
    margin-right: 70px;
    width: 68px;
    height: 68px;
  }
  .right-content{
    display: inline-block;
    vertical-align: middle;
    width: 492px;
    .tip{
      margin-bottom: 10px;
    }
    .error {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 400;
      // line-height: 34px;
      color: #F44336;
    }
    .btn {
      width: 100%;
      height: 54px;
      margin-top: 10px;
      margin-left: 0;
    }
  }
}
</style>
